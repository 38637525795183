export default window.story || {
  avatar: "https://i.imgur.com/XcZvK0j.jpg",
  name: "Tiscali",
  conversion: {
    value: 10.8,
    currency: "EUR",
  },
  pages: [
    {
      id: "24ubfe7s",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: "👋 Ciao, benvenuto/a!",
            layout: {
              top: 80,
              width: "100%",
              fontSize: 27,
              fontWeight: 800,
              textAlign: "center",
              color: "white",
            },
          },
        },
        {
          type: "text",
          props: {
            text:
              "🚀 Vuoi navigare in internet senza aver nessun problema di connessione? 📶",
            layout: {
              top: 175,
              left: 54,
              lineHeight: 1.2,
              width: 300,
              fontSize: 23,
              rotate: -5,
              color: "white",
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Magari! 😍",
                class: "animate__animated animate__pulse animate__infinite",
              },
              {
                text: "Finalmente! 👍",
                class: "animate__animated animate__pulse animate__infinite",
              },
            ],
            layout: {
              top: 330,
            },
          },
        },
        {
          type: "image",
          props: {
            url: "https://media1.giphy.com/media/WraJqGwrIYzAglP6UJ/giphy.gif",
            layout: {
              top: 500,
              left: 120,
              width: 40,
              rotate: 200,
            },
          },
        },
      ],
    },
    {
      id: "gfd7gydhw",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text:
              "💡 Per verificare la tua copertura e darti tutte le informazioni sulla SUPER PROMO, accetta la Privacy Policy",
            layout: {
              top: 130,
              left: 25,
              width: 350,
              fontSize: 20,
              rotate: 0,
              textAlign: "center",
              color: "white",
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Visualizza privacy policy",
                url:
                  "https://www.tiscali.it/privacy/informativa_policy_privacy.html",
                layout: {
                  fontWeight: 300,
                  backgroundColor: "transparent",
                  padding: 10,
                  border: "none",
                  fontSize: 12,
                  color: "#d5d5d5",
                },
              },
              {
                text: "Acconsento 👍",
                class: "animate__animated animate__pulse animate__infinite",
                layout: {
                  backgroundColor: "#4bb238",
                  color: "#fff",
                  border: "3px solid #fff",
                  borderRadius: 0,
                },
              },
              {
                text: "Non acconsento",
                toast: {
                  message:
                    "Ci dispiace che tu abbia negato il consenso. SENZA consenso non potremo contattarti per inviarti la promozione",
                  appearance: "error",
                },
                layout: {
                  fontWeight: 300,
                  backgroundColor: "transparent",
                  padding: 10,
                  border: "none",
                  fontSize: 12,
                  color: "#d5d5d5",
                },
              },
            ],
            layout: {
              top: 350,
            },
          },
        },
      ],
    },
    {
      id: "102jsdf34",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: "🎉 Perfetto, ecco l'offerta Ultrainternet Wireless! 😃",
            layout: {
              top: 61,
              left: 80,
              width: 280,
              fontSize: 20,
              fontWeight: 800,
              rotate: -3,
              color: "white",
            },
          },
        },
        {
          type: "text",
          props: {
            text: `✅ Internet illimitato fino a 100 MEGA

✅ Senza Linea Fissa

✅ Modem Wi-Fi Gratis

✅ Senza Vincoli

✅ Zero Costi Nascosti

📺 2 Mesi GRATIS di Infinity TV per goderti il Meglio del Cinema e delle Serie TV! 🎞
            `,
            layout: {
              top: 170,
              left: 70,
              width: 250,
              fontSize: 20,
              color: "white",
            },
          },
        },
        {
          type: "image",
          props: {
            url: "https://media1.giphy.com/media/WraJqGwrIYzAglP6UJ/giphy.gif",
            layout: {
              top: 460,
              left: 160,
              width: 70,
              rotate: -80,
            },
          },
        },
        {
          type: "image",
          props: {
            url: "https://media1.giphy.com/media/KeKvgOVnqkBMkb2WLS/giphy.gif",
            layout: {
              top: 550,
              left: 310,
              width: 100,
            },
          },
        },
      ],
    },
    {
      id: "fd098udwjs",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text:
              "🎁 Per scoprire tutti i dettagli della SUPER PROMO verifica subito la copertura ⬇️",
            layout: {
              top: 90,
              left: 44,
              width: 300,
              fontSize: 25,
              fontWeight: 800,
              textAlign: "center",
              rotate: 0,
              color: "white",
            },
          },
        },
        {
          type: "text",
          props: {
            text:
              "⏰ ATTENZIONE! IL MIGLIOR PREZZO È ORA! LA PROMO SCADE TRA POCHI GIORNI! ⏰",
            layout: {
              top: 230,
              left: 40,
              width: 310,
              lineHeight: 1.4,
              fontSize: 28,
              fontStyle: "italic",
              textAlign: "center",
              rotate: 0,
              color: "white",
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Verifica velocità! 🚀",
                class: "animate__animated animate__pulse animate__infinite",
              },
              {
                text: "Chiamata gratuita ☎",
                class: "animate__animated animate__pulse animate__infinite",
              },
            ],
            layout: {
              top: 413,
            },
          },
        },
      ],
    },
    {
      id: "3mfsd9fsdj8",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text:
              "👍 Perfetto! Verifichiamo la velocità disponibile al tuo indirizzo 📶",
            layout: {
              top: 86,
              left: 70,
              width: 240,
              textAlign: "center",
              fontSize: 18,
              color: "white",
            },
          },
        },
        {
          type: "input",
          props: {
            field: "phone",
            text: "Inserisci il tuo numero di telefono qua:",
            inputType: "phone",
            placeholder: "es.: 3401234567",
            countryCode: "+39",
            layout: {
              top: 200,
            },
          },
        },
        {
          type: "image",
          props: {
            url:
              "https://media3.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif?cid=ecf05e47wn89yjvfz47aam97lttm8aftu82pbdmrp38u59j8&rid=giphy.gif",
            layout: {
              top: 303,
              left: 0,
              width: 393,
            },
          },
        },
      ],
    },
    {
      id: "dfg89fdgd8d",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: "Di dove sei?",
            layout: {
              top: 86,
              left: 70,
              width: 240,
              textAlign: "center",
              fontSize: 18,
              color: "white",
            },
          },
        },
        {
          type: "input",
          props: {
            field: "city",
            text: "Scrivi la tua città qua:",
            inputType: "text",
            placeholder: "es.: Roma",
            layout: {
              top: 200,
            },
          },
        },
        {
          type: "image",
          props: {
            url:
              "https://media3.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif?cid=ecf05e47wn89yjvfz47aam97lttm8aftu82pbdmrp38u59j8&rid=giphy.gif",
            layout: {
              top: 303,
              left: 0,
              width: 393,
            },
          },
        },
      ],
    },

    {
      id: "gdf67shfsf2",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: "E adesso presentiamoci!",
            layout: {
              lineHeight: 1.3,
              top: 79,
              left: 78,
              width: 240,
              textAlign: "center",
              fontSize: 28,
              color: "white",
            },
          },
        },
        {
          type: "input",
          props: {
            field: "first_name",
            text: "Scrivi il tuo nome e cognome qui in basso! 👇",
            inputType: "text",
            placeholder: "es.: Mario Rossi",
            layout: {
              top: 200,
            },
          },
        },
        {
          type: "image",
          props: {
            url:
              "https://media2.giphy.com/media/aNqEFrYVnsS52/200w.webp?cid=ecf05e47ccaf6c4d79ba2586019fac8b437256ef26e14012&rid=200w.webp",
            layout: {
              top: 400,
              left: 0,
              width: 393,
            },
          },
        },
      ],
    },
    {
      id: "zxcijsf2302",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text:
              "Questi sono i dati da te inseriti, ne confermi la correttezza?",
            layout: {
              top: 85,
              left: 60,
              width: 280,
              fontSize: 22,
              fontWeight: 800,
              color: "white",
            },
          },
        },
        {
          type: "text",
          props: {
            text: `**Nome e cognome:** {{first_name}}

**Città:** {{city}}

**Numero di telefono:** {{phone}}
            `,
            layout: {
              top: 240,
              left: 40,
              width: 300,
              fontSize: 20,
              color: "white",
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Conferma",
              },
              {
                text: "Voglio reinserire i dati",
                navigateToIndex: 4,
              },
            ],
            layout: {
              top: 435,
            },
          },
        },
      ],
    },

    {
      id: "456jn5i43n5",
      style: {
        backgroundColor: "#5f57a1",
      },
      disableBackNavigation: true,
      blocks: [
        {
          type: "image",
          props: {
            url: "https://media1.giphy.com/media/l3vR4yk0X20KimqJ2/giphy.gif",
            layout: {
              top: 0,
              left: 0,
              width: 393,
              height: 625,
            },
          },
        },

        {
          type: "text",
          props: {
            text:
              "Perfetto, un nostro operatore provvederà a contattarti entro pochi minuti!",
            layout: {
              top: 140,
              left: 46,
              width: 300,
              fontSize: 32,
              rotate: 0,
              lineHeight: 1.3,
              backgroundColor: "#000",
              paddingTop: 30,
              paddingBottom: 30,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 5,
              color: "white",
              textAlign: "center",
            },
          },
        },
      ],
    },
  ],
};
