import React from "react";
import { Box, Flex, Text } from "rebass";
import _ from "lodash";
import { css } from "emotion";
import Block from "../block";
import { getBoxSize, resetVideoPlayback } from "../../utils";
import useStories from "../../hooks/stories";
import Navigator from "../navigator";
import Featured from "../featured";

const Stories = () => {
  const {
    avatar,
    avatarBorderRadius,
    hideName,
    name,
    pages,
    videoRefs,
    currentStoryIndex,
    isInputFocused,
    isVideoPlaying,
    dispatch,
  } = useStories();

  const getCurrentStory = () => pages[currentStoryIndex];

  return (
    <>
      <Flex
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {pages.map((page) => (
          <Box
            key={page.id}
            sx={{
              position: "absolute",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              width: getBoxSize().width,
              height: getBoxSize().height,
              ...page.style,
              opacity: getCurrentStory().id === page.id ? 1 : 0,
              left: getCurrentStory().id === page.id ? "initial" : -3000,
            }}
          >
            <Flex
              flexDirection="row"
              alignItems="center"
              className={css`
                position: absolute;
                top: 15px;
                left: 15px;
                z-index: 20;
              `}
            >
              <img
                className={css`
                  border-radius: ${typeof avatarBorderRadius !== "undefined"
                    ? avatarBorderRadius
                    : 35}px;
                  border-radius: 35px;
                  height: 35px;
                  width: auto;
                  margin-right: 10px;
                `}
                alt={name}
                src={avatar}
              />

              {!hideName && (
                <Text
                  sx={{
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  {name}
                </Text>
              )}
            </Flex>

            <Box
              sx={{
                display: ["block", "none"],
              }}
            >
              <Navigator />
            </Box>

            <Box
              className={css`
                display: flex;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                transition: filter 0.15s ease, backdrop-filter 0.15s ease;

                ${isInputFocused &&
                `
                    backdrop-filter: blur(10px);
                    filter: blur(10px);
                  `}
              `}
            >
              {page.blocks
                .filter(
                  (block) =>
                    ["input", "buttons", "iframe"].indexOf(block.type) === -1
                )
                .map((block, index) => (
                  <Block
                    key={`${page.id}-${index}`}
                    pageId={page.id}
                    type={block.type}
                    {...block.props}
                  />
                ))}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  width: "50%",
                }}
                onClick={() => {
                  if (
                    isInputFocused ||
                    getCurrentStory().disableBackNavigation
                  ) {
                    return;
                  }

                  resetVideoPlayback({
                    videoRefs,
                  });

                  dispatch({
                    type: "IS_VIDEO_PLAYING_TOGGLE",
                    payload: false,
                  });

                  const nextStoryId = _.get(pages[currentStoryIndex - 1], "id");

                  if (
                    videoRefs[nextStoryId] &&
                    videoRefs[nextStoryId].current
                  ) {
                    videoRefs[nextStoryId].current.play();

                    dispatch({
                      type: "IS_VIDEO_PLAYING_TOGGLE",
                      payload: true,
                    });
                  }

                  dispatch({
                    type: "PAGE_PREVIOUS",
                  });
                }}
              />

              <Box
                className="next"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  width: "50%",
                }}
                onClick={() => {
                  if (
                    !_.filter(
                      page.blocks,
                      (block) =>
                        ["input", "buttons", "iframe"].indexOf(block.type) > -1
                    ).length
                  ) {
                    resetVideoPlayback({
                      videoRefs,
                    });

                    dispatch({
                      type: "IS_VIDEO_PLAYING_TOGGLE",
                      payload: false,
                    });

                    const nextStoryId = _.get(
                      pages[currentStoryIndex + 1],
                      "id"
                    );

                    if (
                      videoRefs[nextStoryId] &&
                      videoRefs[nextStoryId].current
                    ) {
                      videoRefs[nextStoryId].current.play();

                      dispatch({
                        type: "IS_VIDEO_PLAYING_TOGGLE",
                        payload: true,
                      });
                    }

                    dispatch({
                      type: "LAST_FEATURED_SHOWN_INDEX_SET",
                      payload: currentStoryIndex,
                    });

                    return dispatch({
                      type: "PAGE_NEXT",
                    });
                  }

                  dispatch({
                    type: "SHOW_INTERACTION_FEEDBACK_TOGGLE",
                    payload: true,
                  });

                  setTimeout(() => {
                    dispatch({
                      type: "SHOW_INTERACTION_FEEDBACK_TOGGLE",
                      payload: false,
                    });
                  }, 600);
                }}
              />
            </Box>

            {isVideoPlaying && (
              <Box
                className="pause"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                onClick={() => {
                  videoRefs[getCurrentStory().id].current.pause();

                  dispatch({
                    type: "IS_VIDEO_PLAYING_TOGGLE",
                    payload: false,
                  });
                }}
              />
            )}

            {page.blocks
              .filter(
                (block) =>
                  ["input", "buttons", "iframe"].indexOf(block.type) !== -1
              )
              .map((block, index) => (
                <Block
                  key={`${page.id}-${index}`}
                  pageId={page.id}
                  type={block.type}
                  {...block.props}
                />
              ))}
          </Box>
        ))}

        <Box
          sx={{
            display: ["none", "block"],
          }}
        >
          <Navigator />
        </Box>

        <Featured />
      </Flex>
    </>
  );
};

export default Stories;
