import React, { createContext, useReducer, useEffect, createRef } from "react";
import _ from "lodash";
import defaultState from "../../state";

const StoriesContext = createContext();

const StoriesProvider = ({ children }) => {
  const initialState = {
    ...defaultState,
    currentStoryIndex: 0,
    lastFeaturedShownIndex: -1,
    isInputFocused: false,
    isVideoPlaying: false,
    showInteractionFeedback: false,
    fields: {},
    videoRefs: _(defaultState.pages)
      .filter((page) => {
        return _.find(page.blocks, (block) => block.type === "video");
      })
      .keyBy((page) => page.id)
      .mapValues(() => createRef())
      .value(),
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "IS_INPUT_FOCUSED_TOGGLE":
        return {
          ...state,
          isInputFocused: action.payload,
        };

      case "IS_VIDEO_PLAYING_TOGGLE":
        return {
          ...state,
          isVideoPlaying: action.payload,
        };

      case "LAST_FEATURED_SHOWN_INDEX_SET":
        return {
          ...state,
          lastFeaturedShownIndex: action.payload,
        };

      case "SHOW_INTERACTION_FEEDBACK_TOGGLE":
        return {
          ...state,
          showInteractionFeedback: action.payload,
        };

      case "FIELDS_ADD":
        return {
          ...state,
          fields: {
            ...state.fields,
            ...action.payload,
          },
        };

      case "PAGE_GO_TO":
        return {
          ...state,
          currentStoryIndex: action.payload,
        };

      case "PAGE_PREVIOUS":
        return {
          ...state,
          currentStoryIndex:
            0 === state.currentStoryIndex
              ? state.currentStoryIndex
              : state.currentStoryIndex - 1,
        };

      case "PAGE_NEXT":
        return {
          ...state,
          currentStoryIndex:
            state.pages.length - 1 === state.currentStoryIndex
              ? state.currentStoryIndex
              : state.currentStoryIndex + 1,
        };

      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.dataLayer = [
      {
        client: { name: state.name },
        type: "Adverstory",
      },
    ];
  }, [state.name]);

  return (
    <StoriesContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </StoriesContext.Provider>
  );
};

export { StoriesContext, StoriesProvider };
